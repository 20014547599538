<template>
	<div id="exam" v-loading="loading">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>教务</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">考试管理</a></el-breadcrumb-item>
			</el-breadcrumb>
			
			<div class="head u-f-item">
				<span class="span">选择条件</span>
				
				<el-select size="small" v-model="year" placeholder="学年" style="width: 150px;margin-left: 10px" @change="changeYear">
					<el-option v-for="item in yearList" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
				<el-select size="small" v-model="semester" placeholder="学期" style="width: 150px;margin-left: 10px" @change="changeSemester">
					<el-option v-for="item in semesterList" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
				<el-select size="small" v-model="grade" placeholder="年级" style="width: 150px;margin-left: 10px" @change="changeGrade">
					<el-option v-for="item in gradeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</div>
			<div class="content" style="padding-top: 20px">
				<template v-if="tabIndex==0">
					<div class="title u-f-item u-f-jsb">
						<div>
							共{{total}}条数据，
							<span class="btn" @click="selectAll">选择全部</span>
						</div>
						<div class="u-f-item">
							<!-- <el-input placeholder="请输入内容" v-model="sousuo" size="small" style="margin-right: 10px;">
								<i slot="prefix" class="el-input__icon el-icon-search"></i>
							</el-input> -->
							<el-button size="small" @click="VisibleTap(1)">添加考试</el-button>
							<!-- <el-button size="small">批量录入</el-button> -->
							<!-- <el-button size="small" type="primary">导出</el-button> -->
						</div>
					</div>
					<el-table
						:data="list"
						height="600px"
						ref="multipleTable"
						tooltip-effect="dark"
						@selection-change="handleSelectionChange"
						style="width: 100%;border: 1px solid #EEEEEE;"
						key="2"
					>
						<el-table-column type="selection" width="55"></el-table-column>
						<el-table-column label="序号" type="index"  width="150" align="center"></el-table-column>
						<el-table-column prop="name" label="考试名称" align="center"></el-table-column>
						<el-table-column prop="starttime" label="时间" align="center">
							<template slot-scope="scope">
								<div>
									{{scope.row.starttime}}~{{scope.row.endtime}}
								</div>
							</template>
						</el-table-column>
						<el-table-column label="操作" align="center">
							<template slot-scope="scope">
								<el-button @click="VisibleTap(2,scope.row)" type="text" size="small">编辑</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div class="u-f-item u-f-jsb" style="padding: 15px 0;">
						<el-button type="danger" plain size="mini" @click="delClass">删除</el-button>
						<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page.sync="page"
							:page-size="limit"
							layout="total, prev, pager, next"
							:total="total"
						></el-pagination>
					</div>
				</template>
				<template v-if="tabIndex==1">
					<div class="title u-f-item u-f-jsb">
						<div>
							共{{total}}条数据，
							<span class="btn" @click="selectAll">选择全部</span>
						</div>
						<div class="u-f-item">
							<el-input placeholder="请输入内容" v-model="sousuo" size="small" style="margin-right: 10px;">
								<i slot="prefix" class="el-input__icon el-icon-search"></i>
							</el-input>
							<!-- <el-button size="small">批量录入</el-button>
							<el-button size="small" type="primary">导出</el-button> -->
						</div>
					</div>
					<el-table
						:data="list"
						height="650px"
						ref="multipleTable"
						tooltip-effect="dark"
						@selection-change="handleSelectionChange"
						style="width: 100%;border: 1px solid #EEEEEE;"
						key="3"
					>
						<el-table-column label="序号" type="index"  width="150" align="center"></el-table-column>
						<el-table-column prop="name" label="名称" align="center"></el-table-column>
						<el-table-column prop="subjects_text" label="学科" align="center"></el-table-column>
						<el-table-column label="操作" width="300" align="center">
							<template slot-scope="scope">
								<router-link tag="a" target="_blank" :to="{name:'exam_detail',query:{id: scope.row.id}}">
									<el-button type="text" size="small">详情</el-button>
								</router-link>
								<el-button @click="VisibleTap(4, scope.row)" type="text" size="small">导入成绩</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div class="u-f-item u-f-jsb" style="padding: 15px 0;">
						<el-button type="danger" plain size="mini" @click="delClass">删除</el-button>
						<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page.sync="page"
							:page-size="limit"
							layout="total, prev, pager, next"
							:total="total"
						></el-pagination>
					</div>
				</template>
			</div>
			<el-dialog :title="VisibleTitle" :visible.sync="addVisible" width="500px" :before-close="handleClose">
				<blockquote style="margin: 0;" v-if="type==1||type==2">
					<div class="edit-box">
						<div class="u-f-item input-box">
							<div class="input-lebal">
								<span>*</span>
								考试名称
							</div>
							<el-input v-model="form.name" placeholder="请输入考试名称" style="width: 70%;"></el-input>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal">
								<span>*</span>
								考试科目
							</div>
							<el-select v-model="form.subjects_arr" multiple placeholder="请选择考试科目" style="width: 70%;">
								<el-option v-for="item in subjectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal">
								<span>*</span>
								考试开始时间
							</div>
							<el-date-picker type="datetime" placeholder="选择日期" v-model="form.starttime" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" style="width: 70%;"></el-date-picker>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal">
								<span>*</span>
								考试结束时间
							</div>
							<el-date-picker type="datetime" placeholder="选择日期" v-model="form.endtime" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" style="width: 70%;"></el-date-picker>
						</div>
					</div>
				</blockquote>
				<blockquote style="margin: 0;" v-else-if="type==3">
					<el-table
						:data="scoreList"
						height="400px"
						ref="multipleTable1"
						tooltip-effect="dark"
						@selection-change="handleSelectionChange"
						style="width: 100%;border: 1px solid #EEEEEE;"
						key="2"
					>
						<el-table-column label="序号" type="index"  width="70"></el-table-column>
						<el-table-column prop="realname" label="姓名"></el-table-column>
						<el-table-column prop="class_name" label="班级"></el-table-column>
						<el-table-column prop="student_num" label="学号"></el-table-column>
						<el-table-column label="考试科目">
							<template slot-scope="scope">
								<div>
									<span v-for="(item, index) in scope.row.subjects_arr" :key="index">{{item.name}},</span>
								</div>
							</template>
						</el-table-column>
					</el-table>
					<div class="page" style="padding: 15px 0;text-align: right">
						<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange1"
							:current-page.sync="s_page"
							:page-size="limit"
							layout="total, prev, pager, next"
							:total="s_total"
						></el-pagination>
					</div>
				</blockquote>
				<blockquote style="margin: 0;" v-else-if="type==4">
					<div class="edit-box">
						<el-button size="small" @click="downModal(down_id)">点击下载成绩导入模板</el-button>
						<div class="u-f-item input-box">
							<div class="input-lebal">
								<span>*</span>
								上传文件
							</div>
							<el-input v-model="file.name" disabled placeholder="请选择要上传的文件" style="width: 60%;margin-right:10px"></el-input>
							<el-button size="middle" style="position: relative;"><input ref="filElem" type="file" class="upload-file" @change="getFile">选择文件</el-button>
						</div>
					</div>
				</blockquote>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="closeVisible" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submit">确 定</el-button>
				</span>
			</el-dialog>
		</index>	
	</div>
</template>

<script>
import index from '@/components/common/index.vue'
export default {
	components: {
			index
	},
	props: {
		
	},
	data() {
		return {
			type: '',
			loading: false,
			condition: 0,
			conditionList: [],
			year: 2020,
			yearList: [],
			semester: 1,
			semesterList: [],
			grade: '',
			gradeList: [],
			tabIndex: 0,
			menu: [
				{name: '考试管理'},
				{name: '成绩管理'},
			],

			action: '',
			addVisible: false,
			VisibleTitle: '',
			sousuo: '',
			page: 1,
			limit: 10,
			total: 0,
			list: [],
			subjectList: [],
			form: {
				name: '',
				subjects: '',
				subjects_arr: [],
				starttime: '',
				endtime: ''
			},
			file: '',
			down_id: '',
			s_page: 1,
			s_total: 0,
			scoreList: []
		};
	},
	created() {},
	mounted() {
		this.getYearList();
		this.getSubjectList()
	},
	methods: {
		selectAll(){
			let self = this;
			this.list.forEach(row => {
				self.$refs.multipleTable.toggleRowSelection(row, true);
			});
		},
		twacherTap(index) {
			this.tabIndex = index;
		},
		// 学年列表
		getYearList(){
			this.$api.setting.getSemesterList().then(res=>{
				if(res.data.code==1){
					this.yearList = res.data.data;
					if(res.data.data.length){
						if( this.yearList.filter(item=>item.is_default==2).length>0){
							this.year = this.yearList.filter(item=>item.is_default==2)[0].id;
						}else{
							this.year = this.yearList[0].id
						}
					}else{
						this.year = ''
					}
					this.getSemesterList();
				}
			})
		},
		changeYear(e){
			this.year = e;
			this.page = 1;
			this.list = [];
			this.getSemesterList()
		},
		// 学期列表
		getSemesterList(){
			this.$api.setting.getXueQiList({year_id: this.year}).then(res=>{
				if(res.data.code==1){
					this.semesterList = res.data.data;
					if(res.data.data.length){
						if( this.semesterList.filter(item=>item.is_default==2).length>0){
							this.semester = this.semesterList.filter(item=>item.is_default==2)[0].id;
						}else{
							this.semester = this.semesterList[0].id
						}
					}else{
						this.semester = '';
					}
					this.getGradeList()
				}
			})
		},
		changeSemester(e){
			this.semester = e;
			this.page = 1;
			this.list = [];
			this.getGradeList()
		},
		// 年级列表
		getGradeList(){
			this.$api.setting.getGradeList({}).then(res=>{
				if(res.data.code==1){
					this.gradeList = res.data.data.rows;
					this.grade = this.gradeList[0].id;
					this.examData()
				}
			})
		},
		// 切换年级
		changeGrade(e){
			this.grade = e;
			this.page = 1;
			this.list = [];
			this.examData()
		},
		// 学科列表
		getSubjectList(){
			this.$api.setting.subjectList({}).then(res=>{
				if(res.data.code==1){
					this.subjectList = res.data.data;
				}
			})
		},
		// 关闭弹窗
		handleClose (done) {
			this.initialize()
			done()
		},
		handleSelectionChange(val) {
			let arr = [];
			for(let i in val){
				arr.push(val[i].id);
			}
			this.ids = arr;
			console.log(this.ids)
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.examData()
		},
		handleCurrentChange1(val) {
			this.s_page = val;
			this.getExamInfo()
		},
		filterTag(value, row) {
			return row.tag === value;
		},
		filterHandler(value, row, column) {
			const property = column['property'];
			return row[property] === value;
		},
		examData(){
			let data = {
				year_id: this.year,
				semester_id: this.semester,
				grade_id: this.grade,
				page: this.page,
				limit: this.limit
			}
			this.$api.student.examList(data).then(res=>{
				if(res.data.code==1){
					this.list = res.data.data.rows;
					this.total = res.data.data.total
				}
			})
		},
		// 获取考试信息
		getExamInfo(id){
			this.$api.student.detailexamscore({
				exam_id: id,
				page: this.s_page,
				limit: 10
			}).then(res=>{
				if (res.data.code == 1) {
					this.scoreList = res.data.data.list;
					this.s_total = res.data.data.total
				} else {
					this.$message.error(res.data.msg);
				}
			})
		},
		// 弹窗
		VisibleTap(type,item){
			this.type = type;
			if(type==1){
				this.VisibleTitle = '添加考试';
			}else if(type==2){
				this.VisibleTitle = '编辑考试';
				this.id = item.id;
				this.form.name = item.name;
				this.form.starttime = item.starttime;
				this.form.endtime = item.endtime;
				let subjects = item.subjects.split(',');
				subjects = subjects.map(item=>{
					return Number(item)
				})
				this.form.subjects_arr = subjects;
			}else if(type==3){
				this.VisibleTitle = '查看成绩';
				this.getExamInfo(item.id)
			}else if(type==4){
				this.VisibleTitle = '导入成绩';
				this.down_id = item.id;
			}
			this.addVisible = true;
		},
		// 关闭弹窗
		closeVisible(){
			this.initialize()
			this.addVisible = false;
		},
		initialize(){
			this.form = {
				name: '',
				subjects: '',
				subjects_arr: [],
				starttime: '',
				endtime: ''
			}
		},
		// 添加、编辑分班
		submit(){
			if(!this.year||this.year==0) return this.$message.error('请选择学年');
			if(!this.semester||this.semester==0) return this.$message.error('请选择学期');
			if(!this.grade||this.grade==0) return this.$message.error('请选择年级');
			if(this.type==1){
				this.addClass()
			}else if(this.type==2){
				this.editClass()
			}else if(this.type==4){
				this.exportXls()
			}
		},
		// 添加考试
		addClass(){
			this.form.subjects = this.form.subjects_arr.join(',');
			this.$api.student.addexam({
				year_id: this.year,
				semester_id: this.semester,
				grade_id: this.grade,
				name: this.form.name,
				subjects: this.form.subjects,
				starttime: this.form.starttime,
				endtime: this.form.endtime,
			}).then(res=>{
				if (res.data.code == 1) {
					this.$message.success('添加成功');
					this.examData();
					this.addVisible = false;
				} else {
					this.$message.error(res.data.msg);
				}
			})
		},
		// 编辑考试
		editClass(){
			this.form.subjects = this.form.subjects_arr.join(',');
			this.$api.student.editexam({
				id: this.id,
				name: this.form.name,
				subjects: this.form.subjects,
				starttime: this.form.starttime,
				endtime: this.form.endtime
			}).then(res=>{
				if (res.data.code == 1) {
					this.$message.success('修改成功');
					this.examData();
					this.addVisible = false;
				} else {
					this.$message.error(res.data.msg);
				}
			})
		},
		// 删除考试
		delClass(){
			let _this = this;
			if(this.ids.length){
				this.$confirm('确定要删除？','提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				}).then(()=>{
					this.$api.student.delexam({
						id:this.ids
					}).then(res=>{
						if (res.data.code == 1) {
							_this.$message.success('删除成功');
							_this.examData();
						} else {
							_this.$message.error(res.data.msg);
						}
					})
				})
			}else{
				this.$message.error('请选择要删除的考试')
			}
		},
		// 下载成绩导入模板
		downModal(id){
			this.$api.student.downexam({
				exam_id: id
			}).then(res=>{
				if (res.data.code == 1) {
					window.open(res.data.data.path)
				} else {
					this.$message.error(res.data.msg);
				}
			})
		},
		getFile(e){
			console.log(e)
			if(this.$refs.filElem.files.length){
				this.file = this.$refs.filElem.files[0]
			}
		},
		// 导入成绩
		exportXls(){
			let myfile = this.$refs.filElem;
			let files = myfile.files;
			let file = files[0];
			var formData = new FormData();
			formData.append("file", file);
			formData.append("exam_id", this.down_id);
			this.$api.student.exportexam(formData).then(res=>{
				if (res.data.code == 1) {
					this.$message.success('导入成功');
					this.addVisible = false
				} else {
					this.$message.error(res.data.msg);
				}
			})
		},
	}
};
</script>

<style lang="scss" scope>
	#exam{
		.nav-tab-bar-box {
			background-color: #ffffff;
			padding: 0.52vw;
			margin-top: 1.04vw;
			.nav-tab-bar {
				.nav-tab-item {
					color: #6e7a87;
					padding: 0 0.78125vw;
					border-right: 1px solid #f3f3f3;
					cursor: pointer;
					-webkit-touch-callout: none;
					-webkit-user-select: none;
					-khtml-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
				}
				&div:last-child {
					border-right: none;
				}
				.active {
					font-size: 0.833vw;
					color: #63acff;
					position: relative;
					font-weight: bold;
				}
				.active::after {
					position: absolute;
					content: '';
					height: 2px;
					left: 0.78125vw;
					right: 0.78125vw;
					background-color: #63acff;
					bottom: -0.78125vw;
				}
			}
		}
        .head {
            background-color: #ffffff;
            padding: 10px 30px;
            margin-top: 15px;
            .span {
                margin-right: 10px;
                background-color: #f9f9f9;
                color: #8d9da6;
                padding: 3px 10px;
            }
        }
        .content {
            background-color: #ffffff;
            margin-top: 20px;
            padding: 0 30px;
            .title {
				padding: 0 0 15px 0;
				.btn {
					background-color: #f3f3f3;
					font-size: 12px;
					padding: 5px 10px;
					color: #555555;
					cursor: pointer;
				}
			}
			.el-table th > .cell,
			.el-table .cell {
				overflow: hidden; /*超出部分隐藏*/
				white-space: nowrap; /*不换行*/
				text-overflow: ellipsis; /*超出部分文字以...显示*/
			}
			.el-table thead {
				background: #fafafa !important;
			}
			.el-table th {
				padding: 15px 5px !important;
				background: #fafafa !important;
			}
			.el-table::before {
				left: 0;
				bottom: 0;
				width: 100%;
				height: 0;
			}
			.el-table .btn{
				padding: 5px;
				text-align: center;
			}
        }
        .el-dialog__body {
            padding: 20px 20px 50px;
            .edit-box {
                .input-box {
                    margin-top: 35px;
                    .input-lebal {
                        width: 25%;
                        text-align: right;
                        color: #aeb5b8;
                        margin-right: 10px;
                        span {
                            color: #ff7a65;
                            margin-right: 3px;
                        }
                    }
                    .el-icon-remove-outline {
                        font-size: 1.5625vw;
                        color: #d7d9db;
                        margin-left: 10px;
                    }
                }
				.upload-file{
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					opacity: 0;
					cursor: pointer;
				}
            }
        }
        .el-dialog__footer {
            padding: 15px 20px 15px;
            box-shadow: 0px -5px 10px -5px #eeeeee;
        }
    }
	
</style>
